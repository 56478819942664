import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Helmet from 'react-helmet';
import Photos from '../components/Photos';
import Section from '../components/Section';
import Title from '../components/Title';
import Layout, {tt} from '../components/layout';
import {Grid, Item} from './index';

const Centered = styled.div`
  text-align: center;
`;

const Text = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
  
  h1 {
    text-align: center;
  }

  p {
    line-height: 1.4em;

  }

  hr {
    display none
  }
`;

const Tags = styled.div`
  opacity: 0.5;
  margin-bottom: 32px;
  text-align: center;
  letter-spacing: 1;
  text-transform: uppercase;

  a {
    padding: 4px 12px;
    letter-spacing: 0.2em;
  }
`;

const StyledAlso = styled.footer`
  background: #444450;
  padding: 40px;

  h1 {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  a {
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const Also = props => {
  return (
    <StyledAlso>
      <Title>ещё</Title>

      <Grid>
        {props.data.map(({node: post}) => (
          <Item
            key={post.id}
            cover={props.covers[post.fields.slug]}
            post={post}
          />
        ))}
      </Grid>
    </StyledAlso>
  );
};

const PostLayout = props => {
  const {rows, also, covers, tags, title} = props.pageContext;
  const {text} = props.pageContext;

  return (
    <Layout location={props.location}>
      <Helmet title={title}>
        <title>{title}</title>
      </Helmet>
      <Section>
        <Centered>
          <main>
            <Text>
              <h1>{title}</h1>
              {tags && (
                <Tags>
                  {tags.map(t => (
                    <Link key={t} to={`/${t}/`}>
                      {tt[t] || t}
                    </Link>
                  ))}
                </Tags>
              )}
              <div dangerouslySetInnerHTML={{__html: text}} />
            </Text>
          </main>
        </Centered>
        {rows && <Photos rows={rows} />}
      </Section>

      {also && <Also data={also} covers={covers} />}
    </Layout>
  );
};

export default PostLayout;
