import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const StyledPhotos = styled.div`
  margin: 0 -20px 100px;
  /* display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; */
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  flex: 1 0 auto;
  min-width: 280px;
  margin: 20px;

  @media (min-width: 420px) {
    min-width: 380px;
  }

  @media (min-width: 768px) {
    ${({single, vertical}) => {

      if (single && vertical) {
        return 'max-width: 50%';        
      }
      if (vertical) {
        return 'max-width: 50%';
      }

      if (single) {
        return 'max-width: 85%';
      }
      return '';
    }}}
  }
`;

const StyledImg = styled.div`
  flex: 1;
  .gatsby-image-wrapper > img {
    filter: blur(8px);
    transform: scale(1.2);
  }
`;


const Text = styled.p`
  width: 100%;
  min-width: 280px;
  max-width: 768px;
  padding: 20px;
  margin: 1em auto;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const Photos = props => {
  return (
    <StyledPhotos>
      {props.rows.map((items, i) => {
        if (!items.map) {
          return <Text key={i}>{items}</Text>; 
        }
        return (
          <Row key={i}>
            {items.map((p, i) => (
              <Item
                key={i}
                single={items.length === 1}
                vertical={p.sizes.aspectRatio < 1}
              >
                <StyledImg>
                  <Img sizes={p.sizes} />
                </StyledImg>
              </Item>
            ))}
          </Row>
        );
      })}
    </StyledPhotos>
  );
};

export default Photos;
